import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { ReactComponent as Google } from "../../icons/google.svg";
import Stars from "../Stars";

const Container = styled(Box)`
  background: white;
  border-radius: 12px;
  color: initial;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  text-align: left;
  @media (max-width: 470px) {
    font-size: 0.9em;
  }
`;

const GoogleReviews = ({ data }) => (
  <a href="https://g.page/r/Ce4r5TpaIPm9EB0" target="_blank" rel="noreferrer">
    <Container p={3}>
      <Flex alignItems="center">
        <Box width={["20px", "40px"]}>
          <Google />
        </Box>
        <Box ml={[2, 3]}>
          <strong>
            <small style={{ whiteSpace: "nowrap" }}>Kranich Immobilien Rudolstadt</small>
          </strong>
          <Box mt={-2}>
            <Flex alignItems="center" flexWrap="nowrap">
              <Stars dense />
              <Box ml={2}>
                <small>
                  <strong>5.0</strong> Sterne (10)
                </small>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Container>
  </a>
);
export default GoogleReviews;
